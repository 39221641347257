<template>
    <div @click="showUserSelect">
        <el-input
            :placeholder="placeholder"
            :size="size"
            :disabled="disabled"
            :readonly="readonly"
            style="line-hight: 40px"
            v-model="nickname"
            class="input-with-select"
        >
            <el-button
                slot="append"
                :disabled="disabled"
                :readonly="readonly"
                @click.stop="showUserSelect"
                icon="el-icon-search"
            ></el-button>
            <el-button
                slot="suffix"
                v-if="nickname && !disabled"
                :style="
                    'height: ' +
                    (size == 'small' ? 32 : 36) +
                    'px; padding: 9px 12px; width: 24px; color: #999;'
                "
                type="text"
                icon="el-icon-close"
                size="small"
                @click.stop="clear"
            ></el-button>
        </el-input>
        <admin-select
            ref="userSelect"
            v-on="$listeners"
            @doSubmit="selectUsersToInput"
            :limit="limit"
            :selectData="selectData"
            :type="type"
        ></admin-select>
    </div>
</template>
<script>
import adminSelect from "./AdminSelect";
import { getUser, getUserByNo } from "@/api/core/system/user";
export default {
    data() {
        return {
            nickname: "",
            labelValue: this.value,
            selectData: [],
        };
    },
    props: {
        //字段属性 用于回调方法方便取值
        field: {
            type: String,
            default: () => {
                return "";
            },
        },

        limit: Number,
        value: [String, Number],
        label: String,
        size: {
            type: String,
            default: () => {
                return "small";
            },
        },
        placeholder: {
            type: String,
            default: () => {
                return "请选择";
            },
        },
        idField: {
            type: String,
            default: () => {
                return "id";
            },
        },
        readonly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        disabled: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        type: {
            type: String,
            default: () => {
                return undefined;
            },
        },
        valueData: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    components: {
        adminSelect,
    },
    created() {},
    watch: {
        // label: {
        //     handler(newVal) {
        //         this.nickname = newVal;
        //     },
        //     deep: true,
        //     immediate: true,
        // },
        value: {
            handler(newVal) {
                console.log(newVal, "newVal");
                this.selectData = [];
                let labels = this.label?this.label.split(','):[]
                if (newVal) {
                    newVal = newVal + "";
                    newVal.split(",").forEach((id,i) => {
                        if (id) {
                            if (this.idField == "id") {
                                this.selectData.push({nickname:labels[i],id:id})
                                // getUser(id).then(({ data }) => {
                                //     if (data && data.id !== "") {
                                //         this.selectData.push(data);
                                //     }
                                // });
                            } else if (this.idField == "no") {
                                this.selectData.push({nickname:labels[i],no:id})
                                // getUserByNo(id).then(({ data }) => {
                                //     if (data && data.id !== "") {
                                //         this.selectData.push(data);
                                //     }
                                // });
                            }
                        }
                    });
                }
            },
            immediate: true,
            deep: false,
        },
        selectData: {
            handler(newVal) {
                console.log(newVal, "selectData");
                this.nickname = newVal
                    .map((user) => {
                        return user.nickname;
                    })
                    .join(",");
            },
            immediate: true,
            deep: false,
        },
    },
    methods: {
        selectUsersToInput(users) {
            this.selectData = users;
            let ids = users
                .map((user) => {
                    return user[this.idField];
                })
                .join(",");
            let names = users
                .map((user) => {
                    return user.realname ||user.nickname;
                })
                .join(",");
            this.labelValue = ids;
            this.name = names;
            console.log(ids, names, users);
            this.$emit("getValue", ids, names, this.field);
        },
        showUserSelect() {
            this.$refs.userSelect.init();
        },
        clear() {
            this.selectUsersToInput([]);
        },
    },
};
</script>
<style>
.el-form-item__content .el-input-group {
    vertical-align: middle;
}
.el-tag + .el-tag {
    margin-left: 5px;
    margin-bottom: 5px;
}
</style>


