<template>
  <el-select v-model="selectedValue" :placeholder="placeholder" @change="handleChange" class="dict-select" :disabled="disabled" :multiple="multiple" :filterable="filterable" :clearable="clearable">
    <el-option
      v-for="dict in dictData.values"
      :key="dict.key"
      :label="dict.value"
      :value="dict.key"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'dict-select',
  props: {
    type: {
      type: String,
      default: () => { return null }
    },
    value: {
      type: String,
      default: () => { return null }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    multiple: {
      type: Boolean,
      default: () => { return false }
    },
    filterable: {
      type: Boolean,
      default: () => { return false }
    },
    clearable: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      dictData: {},
      selectedValue: this.multiple ? (this.value && this.value.split(',') || []) : this.value
    }
  },
  created() {
    this.getDicts(this.type).then(response => {
      if (response.code == 0) {
        this.dictData = response.data
        this.$emit('onLoadData', response.data)
      }
    })
  },
  methods: {
    handleChange(e) {
      const selectedValue = this.multiple ? (this.selectedValue && this.selectedValue.length > 0 && this.selectedValue.join(',') || '') : this.selectedValue
      this.$emit('input', selectedValue)
      this.$emit('change', selectedValue)
    }
  },
  watch: {
    type() {
      this.dictData = {}
      this.getDicts(this.type).then(response => {
        if (response.code == 0) {
          this.dictData = response.data
        }
      })
    },
    value(val) {
      this.selectedValue = this.multiple ? (val && val.split(',') || []) : val
    }
  }
}
</script>
<style scoped>
.dict-select {
  width: 100%;
}
</style>