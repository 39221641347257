import Cookies from 'js-cookie'

const TokenKey = 'User-Token-jgpfast'
const UserInfoKey = 'Admin-Current-userinfo'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getUserInfo(){
  const str = localStorage.getItem( UserInfoKey )
  if ( str ) return JSON.parse( str )
  return null
}
export function setUserInfo(user) {
  let data = {
     id:user.id,
    username:user.username,
    nickname:user.nickname,
    realname:user.realname,
    idcard:user.idcard,
    mobile:user.mobile,
    gender:user.gender,
    dept:{
       name:''
    }
  }
  if(user.dept) {
    data.dept.name = user.dept.name
  }
  localStorage.setItem(UserInfoKey, JSON.stringify(data))
}
export function removeUserInfo() {
  localStorage.removeItem(UserInfoKey)
}

