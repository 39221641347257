import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layouts";
import EmptyLayout from "@/layouts/EmptyLayout";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export const baseRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: (resolve) => require(["@/views/redirect"], resolve),
      },
    ],
  },
  {
    path: "/404",
    component: Layout,
    component: (resolve) => require(["@/views/error/404"], resolve),
    hidden: true,
  },
  {
    path: "/401",
    component: (resolve) => require(["@/views/error/401"], resolve),
    hidden: true,
  },
  {
    path: "/login",
    name: "Login",
    hidden: true,
    component: () => import("@/views/login"),
  },
  {
    path: "/logout",
    name: "Logout",
    hidden: true,
    component: () => import("@/views/logout"),
  },
  {
    path: "/oauth/authorize",
    name: "OAuthAuthorize",
    hidden: true,
    component: () => import("@/views/login/oauth/authorize"),
  },
  {
    path: "/oauth/callback",
    name: "OAuthCallback",
    hidden: true,
    component: () => import("@/views/login/oauth/callback"),
  },
  {
    path: "/oauth/ybcallback",
    name: "OAuthYBCallback",
    hidden: true,
    component: () => import("@/views/login/oauth/callback"),
  },
  {
    path: "/cas/login",
    name: "CasLogin",
    hidden: true,
    component: () => import("@/views/login/cas/login"),
  },
  {
    path: "/cas/callback",
    name: "CasCallback",
    hidden: true,
    component: () => import("@/views/login/cas/callback"),
  },
  {
    path: "/",
    redirect: "/home/index",
    component: Layout,
    meta: { title: "首页", icon: "shouye" },
    children: [
      {
        path: "index",
        name: "Index",
        component: () => import("@/views/index"),
        meta: {
          title: "首页",
          affix: true,
          icon: "shouye",
        },
      },
      {
        path: "personalCenter",
        name: "PersonalCenter",
        component: () => import("@/views/personalCenter"),
        meta: {
          title: "个人中心",
          icon: "gerenzhongxin",
        },
      },
      {
        path: "board",
        name: "board",
        component: () => import("@/views/test/table/index"), //import('@/views/personalCenter'),
        meta: {
          title: "看板",
          icon: "shebeizonghekanban",
        },
      },
      {
        path: "workbench",
        name: "workbench",
        component: () => import("@/views/test/table/index"), //import('@/views/personalCenter'),
        meta: {
          title: "工作台",
          icon: "a-gongzuotaigongzuozhuo-03",
        },
      },
    ],
  },
  {
    path: "/appointmentConsultation",
    component: Layout,
    meta: { title: "预约咨询", icon: "canguanyuyue" },
    children: [
      {
        path: "appointmentRecord",
        name: "appointmentRecord",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "预约记录",
          icon: "yuyuedan",
        },
      },
      {
        path: "problemType",
        name: "problemType",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "问题类型",
          icon: "liebiao",
        },
      },
      {
        path: "consultationMethod",
        name: "consultationMethod",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "咨询方式",
          icon: "liebiaoxingshi",
        },
      },
      {
        path: "consultant",
        name: "consultant",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "咨询师",
          icon: "yonghu",
        },
      },
      {
        path: "consultingClass",
        name: "consultingClass",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "咨询课",
          icon: "kechengguanli",
        },
      },
    ],
  },
  {
    path: "/studentManage",
    component: Layout,
    meta: { title: "学生管理", icon: "xueshengguanli" },
    children: [
      {
        path: "studentList",
        name: "studentList",
        component: () => import("@/views/studentManage/studentList"), //import('@/views/test/table/index'), //
        meta: {
          title: "学生管理",
          icon: "xueshengguanli",
        },
      },
      {
        path: "majorManage",
        name: "majorManage",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "专业管理",
          icon: "liebiao",
        },
      },
      {
        path: "classManage",
        name: "classManage",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "班级管理",
          icon: "liebiaoxingshi",
        },
      },
      {
        path: "instructorManage",
        name: "instructorManage",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "辅导员管理",
          icon: "yonghu",
        },
      },
      {
        path: "awardsManage",
        name: "awardsManage",
        component: () => import("@/views/test/table/index"), //import('@/views/studentManage/studentList'),
        meta: {
          title: "获奖管理",
          icon: "yuyuedan",
        },
      },
    ],
  },
  {
    path: "/test",
    component: Layout,
    meta: { title: "一级菜单", icon: "xueshengguanli" },
    children: [
      {
        path: "icon",
        component: EmptyLayout,
        name: "Icon",
        meta: {
          title: "二级菜单",
          permissions: ["admin"],
          icon: "xueshengguanli",
        },
        children: [
          {
            path: "awesomeIcon",
            name: "AwesomeIcon",
            component: () => import("@/views/test/table/index"), //import('@/views/test/icon/index'),
            meta: { title: "三级菜单-1" },
          },
          {
            path: "colorfulIcon",
            name: "ColorfulIcon",
            component: () => import("@/views/test/table/index"), //import('@/views/test/icon/colorfulIcon'),
            meta: { title: "三级菜单-2" },
          },
        ],
      },
      {
        path: "table",
        component: () => import("@/views/test/table/index"), //
        name: "Table",
        meta: {
          title: "表格",
          icon: "xueshengguanli",
          permissions: ["admin"],
        },
      },
    ],
  },
  {
    path: "/user",
    component: Layout,
    hidden: true,
    redirect: "noredirect",
    children: [
      {
        path: "profile",
        component: (resolve) =>
          require(["@/views/core/system/user/profile/index"], resolve),
        name: "core-system-user-profile-index",
        meta: { title: "个人中心", icon: "user" },
      },
    ],
  },
  {
    path: "/dict",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "datalist/:dictType",
        component: (resolve) =>
          require(["@/views/core/data/dict/datalist"], resolve),
        name: "core-data-dict-datalist",
        meta: { title: "字典数据", icon: "" },
      },
    ],
  },
  {
    path: "/joblog",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "log",
        component: (resolve) =>
          require(["@/views/core/monitor/job/log"], resolve),
        name: "job-log",
        meta: { title: "调度日志" },
      },
    ],
  },

  {
    path: "/form/data/",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "index/:mid",
        component: (resolve) => require(["@/views/form/data/list"], resolve),
        name: "form-data",
        meta: {
          title: "正在加载...",
          NoKeepAlive: process.ENV == "development",
        },
      },
    ],
  },
  {
    path: "/form/viewdata/:mid",
    component: (resolve) => require(["@/views/form/data/list"], resolve),
    hidden: true,
  },
  {
    path: "/bpm/",
    component: Layout,
    hidden: true,
    children: [
      //   {
      //     path: 'tableinfo/:flowid',
      //     component: (resolve) => require(['@/views/bpm/tabledata/tableinfo'], resolve),
      //     name: 'bpm-tableinfo',
      //     meta: { title: '正在加载...' ,NoKeepAlive:process.ENV=="development"}

      //   },
      {
        path: "tabledata/list/home",
        component: import("@/views/bpm/tabledata/home"),
        name: "首页",
        meta: {
          title: "正在加载...",
          NoKeepAlive: process.ENV == "development",
        },
      },
      {
        path: "tabledata/list/:flowid",
        component: (resolve) =>
          require(["@/views/bpm/tabledata/index"], resolve),
        name: "bpm-tabledata-list",
        meta: {
          title: "正在加载...",
          NoKeepAlive: process.ENV == "development",
        },
      },
    ],
  },

  {
    path: "/iot/",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "node/info/:id",
        component: (resolve) => require(["@/views/iot/node/info"], resolve),
        name: "NodeInfo",
        meta: { title: "节点详情" },
      },
      {
        path: "device/info/:id",
        component: (resolve) => require(["@/views/iot/device/info"], resolve),
        name: "DeviceInfo",
        meta: { title: "设备详情" },
      },
      {
        path: "case/info/:id",
        component: (resolve) => require(["@/views/iot/case/info"], resolve),
        name: "CaseInfo",
        meta: { title: "实例详情" },
      },
      {
        path: "product/info/:id",
        component: (resolve) => require(["@/views/iot/product/info"], resolve),
        name: "ProductInfo",
        meta: { title: "产品详情" },
      },
    ],
  },
];

export const asyncRoutes = [];

const router = new VueRouter({
  base: process.env.BASE_URL,
  // mode: 'history',
  routes: baseRoutes,
});

export function resetRouter() {
  location.reload();
}

export default router;

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

//export default routes
